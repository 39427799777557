import React from "react";
import P_1 from "../assets/web_page-0001.jpg";
import P_2 from "../assets/web_page-0002.jpg";
import P_3 from "../assets/web_page-0003.jpg";


const Post3 = () => {
  return (
    <div>
      <div class="container w-full md:max-w-3xl mx-auto pt-20">
        <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
          {/* {/_ <!--Title--> _/} */}
          <div class="font-sans">
            <p class="text-base md:text-sm text-black font-bold">
              &lt;{" "}
              <a
                href="/blog"
                class="text-base md:text-sm text-black font-bold no-underline hover:underline"
              >
                BACK TO BLOG
              </a>
            </p>
            <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
            Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱? 
            If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗣𝗮𝗿𝘁 𝟭 𝗼𝗳 𝟯.
            </h1>
            <p class="text-sm md:text-base font-normal text-gray-600">
              Published January 2024
            </p>
          </div>

          {/* <!--Post Content--> */}

          {/* <!--Lead Para--> */}
          <p class="py-6">
          USA economy has been growing. We hear from many companies that the business has been strong. However, they do not make as much money as they should.
<br/><br/>

There are two broad reasons:<br/>

(1) Scrap is too high, used more materials than necessary, Sales is selling the wrong products, On-going Processing Issues, and Inventory is too high. These can be corrected by judicious use of 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 𝗮𝗻𝗱 𝗧𝗼𝘁𝗮𝗹 𝗤𝘂𝗮𝗹𝗶𝘁𝘆 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁. More later.
<br/>

(2) 𝗦𝘁𝗿𝗮𝘁𝗲𝗴𝗶𝗰 𝗣𝗹𝗮𝗰𝗲𝗺𝗲𝗻𝘁 𝗼𝗳 𝗩𝗮𝗹𝘂𝗲 𝗣𝗿𝗼𝗽𝗼𝘀𝗶𝘁𝗶𝗼𝗻. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 – 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻. <br/>

<img src={P_1} alt="" />
Slide-2 shows the book ‘Blue Ocean Strategy’ by W. Chan Kim and Renee Mauborgne. A classic book, highly recommended. Authors explain:
<ul>
    <li>· A vast number of companies compete in the ocean of Red Blood, with no distinctive value proposition and are forced to offer lower pricing. 
</li>
    <li>· Using ‘𝗩𝗮𝗹𝘂𝗲 𝗜𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻’ (slide 3), companies can move to the Blue Ocean, where the competition is made irrelevant. Value Innovation means simultaneously to enhance the Value (buyer realized), while lowering the true Costs.
</li>
    <li>· The companies in Blue Ocean may get a few years’ edge.
</li>
<li>· The companies can expand the demand substantially.
</li>
</ul>
<img src={P_3} alt="" />
<br/>

Examples of companies enjoying the swim in Blue Ocean are Apple Inc., Telsa, Disney, and Ford Motor with Model-T in 1908. They all started with a unique vision and kept expanding. They leap-frogged beyond the reach of the competition. In all cases, the demand grew several folds.
<br/>
<br/>

We submit that,<br/>

(1) The transition from Red Ocean to Blue Ocean is on a continuum. There is Red, then Light Red, then Light Blue, and then the Blue. Slide 1 shows the examples of companies in each. It also shows the Black zone of Dying Companies.
<br/>

(2) True Blue zone is possible more readily in Consumer related products where in addition to the utility the products provide, strong emotions are created.
<br/>
<br/>
<img src={P_2} alt="" />
In many Industrial products (e.g. Chemicals, Polymers, Cables, Mechanical parts) one cannot easily expand the total demand beyond the market needs. However, a lot can be done with the ‘𝗩𝗮𝗹𝘂𝗲 𝗜𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻’, where the company can move to Less Red and Light Blue parts. 
<br/>
<br/>

In part-2 of this series, we will talk about the 𝗣𝗿𝗶𝗻𝗰𝗶𝗽𝗹𝗲𝘀 𝗼𝗳 𝗦𝘁𝗿𝗮𝘁𝗲𝗴𝘆 𝗙𝗼𝗿𝗺𝘂𝗹𝗮𝘁𝗶𝗼𝗻 𝗮𝗻𝗱 𝘁𝗵𝗲 𝗜𝗺𝗽𝗹𝗲𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻. Devil is in the details! Execution makes/breaks it.
<br/>
<br/>

Interestingly, the mindset, concepts, and tools of 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 𝗮𝗻𝗱 𝗧𝗼𝘁𝗮𝗹 𝗤𝘂𝗮𝗹𝗶𝘁𝘆 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁 work very effectively to facilitate move towards the Blue side. We will describe that in part-3 of this series.

Suren R Shah and Associates
          </p>
          
            <div className=" text-blue-600">
           
           <a className="text-blue" href="https://www.linkedin.com/feed/update/urn:li:activity:7156695548375244800?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7156695548375244800%29"> This article was originally posted on my LinkedIn </a>
           </div>
          
        </div>
        {/* <!--Tags --> */}
        <div class="text-base md:text-sm text-gray-500 px-4 py-6">Tags:</div>

        {/* <!--Divider--> */}
        <hr class="border-b-2 border-gray-400 mb-8 mx-4"></hr>
      </div>
    </div>
  );
};

export default Post3;

import React from "react";
import con_10 from "../assets/con-10.png";
import con_11 from "../assets/con-11.jpg";

const Consulting = () => {
  return (
    <div>
      {/* content  */}
      <div className=" container w-full md:max-w-3xl mx-auto">
        <h1 className="p-4 text-2xl text-left">
          Consulting and Execution Services
        </h1>
        <div className="">
          <p className="space-y-8 text-justify leading-9">
            <img className="mx-auto h-auto" src={con_10} alt="" />
            We provide services in Cables, Interconnects, Polymers, and
            Renewables segments. We are goal-oriented Implementers. We do not
            lecture you on Management Structure, Styles, and Culture. We roll up
            our sleeves and get to work. We are so confident about our
            performance that on large projects, we can agree to take as much as
            60% of the fees based upon achievement of the mutually agreed upon
            Goals. <br /><br />
            <b> What/how do we do it?</b>
            <ul>
              <li>  &#8226;
                Substantially improve efficiencies, eliminate waste. Rationalize
                and upgrade the factories.
              </li>
              <li>  &#8226;
                Rationalize the customers and develop new ones. Are customers
                looking at you as a partner?
              </li>
              <li>  &#8226;
                Advanced Materials (Thermosets, Themoplastics) and New Products.
              </li>
              <li>  &#8226; Solve ongoing process problems and provide training.</li>
              <li>  &#8226;
                Regulatory approvals: UL, CSA, Intertek, ISO, GMP, and other.
              </li>
              <li>  &#8226;
                Test protocols: Mechanical, Electrical, and Environmental.
              </li>
              <li>  &#8226; Root Cause Analysis.</li>
              <li >  &#8226; Supplier Audits and Selections.</li>
              <li>  &#8226; Corporate ERP, design and implementation.</li>
            </ul>
            <img className="mx-auto" src={con_11} alt="" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consulting;

import React from "react";
import solar from "../assets/solar.jpg";
import Services from "./Services";
import con_2 from "../assets/con-2.jpg";




const Home = () => {
  return (
    <div className="bg-gray-50 mt-2">
      {/* container */}
      <div className="">
        <div className=" min-h-[500px] overflow-hidden justify-center items-center grid grid-cols-1 sm:grid-cols-2 gap-2 ">
          <div className="">
            <h1 className="p-4 text-5xl text-center sm:text-7xl">
              ARE YOU READY TO CAPTURE THE OPPORTUNITIES ?
            </h1>
          </div>
          <div className=" p-20 space-y-5 flex flex-col items-center">
            <p className=" text-lg text-center text-justify leading-9">
              ​USA economy has always been resilient. These are exciting times
              for the companies aided by Industry 4.0, emerging Industry 5.0 and
              boosted by Infrastructure Bill, 2021, Inflation Reduction Act,
              2022. <br />
              Opportunities are plenty for the Companies with a Vision and Plan for Implementation. 
<br />
<b> We focus on Cables, Interconnects, Polymers, and Renewables.</b> <br />
We offer decades of Industry Experience, at a fraction of a cost of a full-time team.  <br /> 

We work with CEOs, COOs, VPs, Director level managers.  We are hands-on! 
            </p>
            <a href="/About">
              <button className="bg-slate-400 hover:bg-slate-600 font-bold py-2 px-2 rounded-full">
                Learn more
              </button>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <img className="relative" src={solar} alt="pic" />
          <img src={con_2} alt="con" />
        </div>
      </div>
      {/* About section 
      <div className="  h-[500px] overflow-hidden bg-white px-10 justify-center items-center grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div className="px-17 space-y-3">
          <h3 className="font-bold text-2xl">About</h3>
          <h4>Founder, Suren Shah</h4>
          <p className=" text-justify leading-8">
            Suren Shah started with B. S. Chemical Engineering. He obtained M.
            S. in Plastics/Polymers Engineering from USA, GPA of 3.95 . He has
            done several classes in Electrical Engineering. Suren also has a
            Certificate in Management from Harvard University, with many MBA
            classes with Harvard faculty, GPA of 3.95. Suren has done Executive
            Programs in Technology Management and People Management from
            Massachusetts Institute of Technology.
          </p>
          {/* Social icons  
          <a href="/About">
            <button className="py-2 px-2 rounded border border-black">
              About Me
            </button>
          </a>
        </div>
        <div className="">
          <img src={pic} alt="pic2" />
        </div>
      </div> */}
      {/* Our services section  */}
      <Services />

      {/* latest posts  */}
      <div className=" bg-black p-4">
        <div className=" rounded-full border-2 border-white ">
        <h2 className="grid place-items-center text-white text-xl   " >
          Announcement
        </h2></div>
        {/* show blog articles */}

        {/* Announcement section */}
        <div className="text-white border border-white px-6 p-10 mx-4 leading-8">
          <p>
            Suren R Shah and Associates has been appointed as
            The Sales agent for USA and Canada for Alphagary’s line of
            Semiconductive compounds. Together they are working on installations
            of Two state-of-art German Made Continuous Kneading lines at the
            factory in India. The factory has been manufacturing these products
            for the last 12 plus years; however, is sold out in capacity. Hence,
            we are installing the new lines that will guarantee a top-of-line
            performance for the USA and Canada markets. The semiconductive
            products will be ready by middle to end of January, 2024.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import con_10 from "../assets/con-10.png";

const Post2 = () => {
  return (
    <div>
      <div class="container w-full md:max-w-3xl mx-auto pt-20">
        <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
          {/* {/_ <!--Title--> _/} */}
          <div class="font-sans">
            <p class="text-base md:text-sm text-black font-bold">
              &lt;{" "}
              <a
                href="/blog"
                class="text-base md:text-sm text-black font-bold no-underline hover:underline"
              >
                BACK TO BLOG
              </a>
            </p>
            <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
              𝗗𝗼𝗲𝘀 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 (𝗧𝗢𝗖) 𝘄𝗼𝗿𝗸 𝗶𝗻 𝗺𝗮𝘁𝘂𝗿𝗲, 𝗲𝘀𝘁𝗮𝗯𝗹𝗶𝘀𝗵𝗲𝗱
              𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀𝗲𝘀? 𝗙𝗼𝗿 𝗲𝘅𝗮𝗺𝗽𝗹𝗲, 𝗖𝗮𝗯𝗹𝗲𝘀, 𝗜𝗻𝘁𝗲𝗿𝗰𝗼𝗻𝗻𝗲𝗰𝘁𝘀, 𝗮𝗻𝗱 𝗣𝗼𝗹𝘆𝗺𝗲𝗿𝘀?
            </h1>
            <p class="text-sm md:text-base font-normal text-gray-600">
              Published June 2023
            </p>
          </div>

          {/* <!--Post Content--> */}

          {/* <!--Lead Para--> */}
          <p class="py-6">
            Absolutely, Positively, Radically, ‘YES’! <br /> TOC provides a
            complete method for breakthrough thinking and provides
            Transformational, and Systematic approach to Management. <br /> Eli
            Goldratt, Creator of TOC, used to say that TOC teaches you to FOCUS.
            It guides on what to focus and also, what NOT to focus on. <br />{" "}
            TOC started in mid 1980s in Operations. Since then, it has been
            applied and showed dramatic improvements in Operations, Sales,
            Supply Chain/Distributions, CASH FLOW, Projects, Service, Retail,
            and even in Business Innovations. <br />
            Genius of Eli Goldratt lied in devising different practical
            application models for different environments. For example, Lean
            Manufacturing principles work extremely well if you have a stable,
            narrow range of products with somewhat consistent work load. Lean
            principles, by themselves, will not work in a Project environment
            where product changes drastically from one project to next or when
            the demand is unstable.
          </p>
          <p>
            <u>Important Guiding Principles of TOC:</u>
            <img src={con_10} alt="" />
            <ul>
              <li>
                Above diagram shows various functional blocks in an
                organization. TOC teaches that any company is a human, living
                organization with a complicated web of 𝗜𝗻𝘁𝗲𝗿𝗱𝗲𝗽𝗲𝗻𝗱𝗲𝗻𝗰𝗶𝗲𝘀. TOC
                systematically analyzes cause-effect relationships in the
                interdependencies and figures out one or two items to focus on.
              </li>
              <li>
                With all the advanced tools of Technology, Communications, True
                Information, Global Resources, this may be the BEST time to be
                in business. It takes the right Management mindset. Dr. Domenico
                Lepore explains that management today requires a cohesive
                integration of scientific methods with the evolved intelligence.
                The scientific methods include TOC, SPC, Control of Variation,
                and Deming’s Plan-Do-Check-Act cycle.
              </li>
              <li>
                The main objective of any for-profit organization is to provide
                Value (products) and Value Delivery (TOC) that allows it to
                offer a proposition that a competition cannot match. In doing
                so, it makes more and more money, period over period. And this
                money is real profit that does not rely on creative accounting!{" "}
                <br />
                TOC teaches us to use only 4 KPI (Key Performance Indicators) at
                the top level:{" "}
                <li> 1. Throughput, which is (Sales- True Variable Costs)</li>
                <li>2. Inventory or Investments</li>
                <li> 3. Operating Expenses</li>
                <li> 4. Cash flow</li>
              </li>
              <li>
                Taiichi Ohno, Toyota had explained succinctly in his books,{" "}
                <br /> 𝘈𝘭𝘭 𝘸𝘦 𝘢𝘳𝘦 𝘥𝘰𝘪𝘯𝘨 𝘪𝘴 𝘭𝘰𝘰𝘬𝘪𝘯𝘨 𝘢𝘵 𝘵𝘩𝘦 𝘵𝘪𝘮𝘦 𝘭𝘪𝘯𝘦 𝘧𝘳𝘰𝘮 𝘵𝘩𝘦
                𝘮𝘰𝘮𝘦𝘯𝘵 𝘵𝘩𝘦 𝘤𝘶𝘴𝘵𝘰𝘮𝘦𝘳 𝘨𝘪𝘷𝘦𝘴 𝘶𝘴 𝘢𝘯 𝘰𝘳𝘥𝘦𝘳 𝘵𝘰 𝘵𝘩𝘦 𝘱𝘰𝘪𝘯𝘵 𝘸𝘩𝘦𝘯 𝘸𝘦
                𝘤𝘰𝘭𝘭𝘦𝘤𝘵 𝘵𝘩𝘦 𝘤𝘢𝘴𝘩. 𝘈𝘯𝘥 𝘸𝘦 𝘢𝘳𝘦 𝘳𝘦𝘥𝘶𝘤𝘪𝘯𝘨 𝘵𝘩𝘢𝘵 𝘵𝘪𝘮𝘦 𝘭𝘪𝘯𝘦… <br /> TOC
                calls the above as Improving the Flow. <br /> Readers, we would
                love to hear from you on experience with TOC. If you like to
                know more, please let us know.
              </li>
            </ul>
          </p>

          <p>
            What can provide even further dramatic improvements to TOC efforts?{" "}
            <br />
            <ol>
              <li>
                1. Defining and permanently addressing on-going Process/Quality
                issues. Many a times, they are the biggest Flow disruptors and
                waste dollars.
              </li>
              <li>
                2. Create additional value by designing and implementing New
                Products with creative use of New Materials.
              </li>
            </ol> <br />
            <div className=" text-blue-600">
           
           <a className="text-blue" href="https://www.linkedin.com/posts/suren-shah-2b092482_leadership-and-business-management-activity-7063623680769077250-atSL?utm_source=share&utm_medium=member_desktop"> This article was originally posted on my LinkedIn </a>
           </div>
          </p>
        </div>
        {/* <!--Tags --> */}
        <div class="text-base md:text-sm text-gray-500 px-4 py-6">Tags:</div>

        {/* <!--Divider--> */}
        <hr class="border-b-2 border-gray-400 mb-8 mx-4"></hr>
      </div>
    </div>
  );
};

export default Post2;

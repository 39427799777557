import React from "react";

const Blog = () => {
  const posts = [
    {
      id: 5,
      title:
        "Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱?If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗽𝗮𝗿𝘁 𝟯 𝗼𝗳 𝟯",
      href: "/Post5",
      description:
        "In part 2, we talked about the Strategy formulation to move from Red to Less Red to Less Blue to Blue parts of the competitive ocean. In this part-3 we will talk about 𝗜𝗺𝗽𝗹𝗲𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻",
      date: "February, 2024",
      datetime: "2020-03-16",
      category: { title: "Marketing", href: "#" },
      author: {
        name: "Suren R Shah",
        role: "Founder",
        href: "/Post5",
      }
    },
    {
      id: 4,
      title:
        "Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱? If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗽𝗮𝗿𝘁 𝟮 𝗼𝗳 𝟯",
      href: "/Post4",
      description:
        "In this part 2, we will talk about the 𝗣𝗿𝗶𝗻𝗰𝗶𝗽𝗹𝗲𝘀 𝗼𝗳 𝗦𝘁𝗿𝗮𝘁𝗲𝗴𝘆 𝗙𝗼𝗿𝗺𝘂𝗹𝗮𝘁𝗶𝗼𝗻. How to move your company from Red Ocean to Light Red to Light Blue to Blue parts of the Ocean?",
      date: "January, 2024",
      datetime: "2020-03-16",
      category: { title: "Marketing", href: "#" },
      author: {
        name: "Suren R Shah",
        role: "Founder",
        href: "/Post4",
        // imageUrl:
        //   "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      }
    },
    {
      id: 3,
      title:
        "Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱? If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗣𝗮𝗿𝘁 𝟭 𝗼𝗳 𝟯.",
      href: "/Post3",
      description:
        "USA economy has been growing. We hear from many companies that the business has been strong. However, they do not make as much money as they should.",
      date: "January, 2024",
      datetime: "2020-03-16",
      category: { title: "Marketing", href: "#" },
      author: {
        name: "Suren R Shah",
        role: "Founder",
        href: "/Post3",
        // imageUrl:
        //   "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      }
    },
    {
      id: 1,
      title:
        "𝗗𝗼𝗲𝘀 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 (𝗧𝗢𝗖) 𝘄𝗼𝗿𝗸 𝗶𝗻 𝗺𝗮𝘁𝘂𝗿𝗲, 𝗲𝘀𝘁𝗮𝗯𝗹𝗶𝘀𝗵𝗲𝗱 𝗯𝘂𝘀𝗶𝗻𝗲𝘀𝘀𝗲𝘀? 𝗙𝗼𝗿 𝗲𝘅𝗮𝗺𝗽𝗹𝗲,𝗖𝗮𝗯𝗹𝗲𝘀, 𝗜𝗻𝘁𝗲𝗿𝗰𝗼𝗻𝗻𝗲𝗰𝘁𝘀, 𝗮𝗻𝗱 𝗣𝗼𝗹𝘆𝗺𝗲𝗿𝘀?",
      href: "/Post2",
      description:
        "Absolutely, Positively, Radically, ‘YES’! TOC provides a complete method for breakthrough thinking and provides Transformational, and Systematic approach to Management. ",
      date: "June, 2023",
      datetime: "2020-03-16",
      category: { title: "Marketing", href: "#" },
      author: {
        name: "Suren R Shah",
        role: "Founder",
        href: "/Post2",
        // imageUrl:
        //   "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      }
    },
    {
      id: 2,
      title:
        "Total Quality Management (#TQM), How does it fit with the Thoery of Constaints(#TOC)?",
      href: "/Post1",
      description:
        "TOC and TQM together can achieve truly remarkable bottom-line Results. TQM First: Over past 30 years, various initiatives have contributed to improve our understanding of Total Quality Management. ",
      date: "June, 2023",
      datetime: "2020-03-16",
      category: { title: "Marketing", href: "#" },
      author: {
        name: "Suren R Shah",
        role: "Founder",
        href: "/Post1",
        // imageUrl:
        //   "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      }
    }

  
  
    // More posts...
  ];
  return (
    <div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              From the blog
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Learn how to grow your business with our expert advice.
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  {/* <img
                    src={post.author.imageUrl}
                    alt=""
                    className="h-10 w-10 rounded-full bg-gray-50"
                  /> */}
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href={post.author.href}>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;

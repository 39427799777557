import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="navbar-static-top w-full h-[90px] flex justify-between items-center px-4 bg-white text-gray-700">
      <div className="font-bold">
        <a href="/">  Suren R Shah and Associates</a>
       </div>
      <div>
        <ul className="hidden md:flex">
          <li>
            <a href="/">Home</a>
          </li>

          <li>
            <a href="/About">Why Us?</a>
          </li>
          <li>
            <a href="/New_PM">New Products & Materials</a>
          </li>
          <li>
            <a href="/Sales">Sales Representation</a>
          </li>
          <li>
            <a href="/TOC_TQM">TOC & TQM</a>
          </li>
          <li>
            <a href="/Consulting">Consulting & Execution</a>
          </li>
          

          <li>
            <a href="/Blog">Blog</a>
          </li>

          <li>
            <a href="/Contact">Contact</a>
          </li>
        </ul>
      </div>
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 right-0 px-14 w-full h-screen bg-white flex flex-col items-center justify-center "
        }
      >
        <li className="py-6 text-4xl">
          <a href="/">Home</a>
        </li>
        <li className="py-6 text-4xl">
          <a href="/About">Why Us?</a>
        </li>
        <li className="py-6 text-4xl">
          <a href="/New_PM">New Products & Materials</a>
        </li>
        <li className="py-6 text-4xl">
          <a href="/Sales">Sales Representation</a>
        </li>
        <li className="py-6 text-4xl">
          <a href="/TOC_TQM">TOC & TQM</a>
        </li>
        <li className="py-6 text-4xl">
          <a href="/Consulting">Consulting & Implementation</a>
        </li>
       
        <li className="py-6 text-4xl">
          <a href="/Blog">Blog</a>
        </li>

        <li className="py-6 text-4xl">
          <a href="/Contact">Contact</a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

import React from "react";
import Sal_8 from "../assets/sal-8.jpg";
import Sal_9 from "../assets/sal-9.jpg";

const Sales = () => {
  return (
    <div>
      {/* content  */}
      <div className=" container w-full md:max-w-3xl mx-auto">
        <h1 className="p-4 text-2xl text-left">
          Sales Representation on Specialty Products
        </h1>
        <div className="">
          <p className="space-y-8 text-justify leading-9">
            <img className="mx-auto h-[400px]" src={Sal_9} alt="" />
            This is an area where we bring all our Business and Technical acumen
            together. The customers are sophisticated and expect the Salesperson
            to have detailed technical knowledge of the product’s unique
            attributes.
            <br /> We do the following:
            <ol>
              <li>
               <b> 1. Technical Marketing which includes the</b>
                <ul>
                  <li>
                    Meeting the customers to understand their requirements.
                  </li>
                  <li>
                    Present the solution that we have to offer to meet/exceed
                    the requirements.
                  </li>
                  <li>Arrange product samples and trial evaluations. </li>
                  <li>
                    Follow through the internal and external tests, to include
                    regulatory tests.
                  </li>
                </ul>
              </li>
              <br />
              <li>
               <b> 2. Sales Management which includes,</b>
                <ul>
                  <li>Set up of all customer information in our ERP system.</li>
                  <li>Set up of all the pricing and logistics. </li>
                  <li>
                    Work with inside Customer Service personnel to track and
                    deliver orders.
                  </li>
                  <li>
                    Quickly address any issues that concern the customers.
                  </li>
                  <li>Satisfy and delight the customers. </li>
                </ul>
              </li>
            </ol>
            <img className="mx-auto h-[300px]" src={Sal_8} alt="" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sales;

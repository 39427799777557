import React from "react";
import TOCTQM from "../assets/TOCTQM.jpg";
import Total from "../assets/Total-Q-M.png";
import Plan from "../assets/PlanCycle.png";
import comple from "../assets/completion.png";

const TOC_TQM = () => {
  return (
    <div className="">
      {/* content  */}
      <div className=" container w-full md:max-w-3xl mx-auto">
        <h1 className="p-4 text-2xl text-left">
          Breakthrough Financial Improvements using Theory of Constraints and
          Total Quality Management
        </h1>
        <div className="">
          <p className="space-y-8 text-justify leading-9">
            <img className="mx-auto " src={comple} alt="" />
            Theory of Constraints (TOC) was developed by Dr. Eliyu Goldratt in
            1980’s. He brought the rigor of Physics and Scientific Thinking
            first to the Softer areas of Operations Management, and then to many
            other areas to include Project Management, Distribution Management,
            Sales Management, Retail, and even Innovation. Dr. Goldratt stated
            that TOC means FOCUS. TOC philosophy believes that People are Good
            (we discourage the layoffs). Once the TOC methods are applied
            properly, there is inherent simplicity in seemingly complex
            situations. Win-win solutions are possible. TOC takes a holistic
            approach and selects Effectiveness over Efficiency. See our Blog-1
            in LinkedIn. <u>Does TOC work in Mature Businesses?</u> At Suren R
            Shah and Associates, we have Suren Shah and several Project Leaders
            with superior skills in TOC implementations. Additionally, with our
            strong background in Engineering and Quality Control, we go a step
            further. We combine TOC with Total Quality Management to offer even
            more complete solutions.
            <img className="mx-auto" src={Total} alt="" />
            <img className="mx-auto " src={TOCTQM} alt="" />

            See our Blog-2 on LinkedIn.
            <u>Total Quality Management (TQM). How does it fit with TOC?</u>
            <br /> TOC and TQM together can achieve truly remarkable Bottom-line
            Results. For example,
            <ul>
              <li> • 30 to 70% reductions in Lead times.</li>
              <li> • 25 to 60% improvements in Profits</li>
              <li>• 25 to 60% improvements in Cash flow.</li>
            </ul>
            Again, we are confident and will agree to take up to 60% of our fees
            based upon achievement of Mutually agreed upon Goals.
            <img className="mx-auto" src={Plan} alt="" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default TOC_TQM;

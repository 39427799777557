import "./App.css";
import About from "./components/About";
import Blog from "./components/Blog";
import Consulting from "./components/Consulting";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import NewPm from "./components/New_PM";
import Sales from "./components/Sales";
import Services from "./components/Services";
import { Routes, Route } from "react-router-dom";
import TOC_TQM from "./components/TOC_TQM";
import Post1 from "./posts/Post1";
import Post2 from "./posts/Post2";
import Post3 from "./posts/Post3";
import Post4 from "./posts/Post4";
import Post5 from "./posts/Post5";


function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/New_PM" element={<NewPm />} />
        <Route path="/Sales" element={<Sales />} />
        <Route path="/Consulting" element={<Consulting />} />
        <Route path="/TOC_TQM" element={<TOC_TQM />} />
        <Route path="/Post1" element={<Post1 />} />
        <Route path="/Post2" element={<Post2 />} />
        <Route path="/Post3" element={<Post3 />} />
        <Route path="/Post4" element={<Post4 />} />
        <Route path="/Post5" element={<Post5 />} />

        
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

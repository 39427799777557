import React from "react";
import ab_4 from "../assets/ab-4.jpg";
import pic from "../assets/pic.png";

const About = () => {
  return (
    <div className="">
      <img className="h-[500px] w-full" src={ab_4} alt="" />
      {/* <div className=" flex items-end p-4 "></div> */}

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-white bg-black h-auto bg-[url">
        <div className=" flex items-center p-16">
          <h1 className="text-3xl">Why Suren R Shah and Associates?</h1>
        </div>
        <div className="flex justify-center items-center text-justify p-8 mx-3 ">
          <p className="leading-8 text-xl">
            We offer decades of Industry Experience with an Impeccable track
            record. We are hands-on, Goal Oriented implementers. We quickly
            figure out what needs to be done, prove the concept to you, and get
            to work. <br />
            <br /> We DO NOT lecture you on the Management Structure, Styles,
            and Culture. There are different Management approaches that are
            appropriate for various stages of growth in a company. We assume you
            know that and have the proper structure in place.
            <br /> <br /> We DO recommend some modifications of Measurement Matrix and
            Incentives. This is based upon our Goal Oriented Implementations. On
            some of the larger projects with promising Breakthrough Financial
            Performances, we are so confident that we will take 60% of our fees
            based upon achieving the mutually agreed upon Goals.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 ">
        <div className="flex flex-col justify-center">
          <img className="mx-auto " src={pic} alt="" />
        </div>
        <div className="mx-10 my-8 space-y-2 text-2xl">
          <h2 className="font-bold text-xl">Suren Shah</h2>
          <h4>Managing Director, Leads all Major Projects</h4>
          <p className="leading-8  text-justify">
            Suren Shah started with B. S. Chemical Engineering. He obtained M.
            S. in Plastics/Polymers Engineering from USA, GPA of 3.95 . He has
            done several classes in Electrical Engineering. Suren also has a
            Certificate in Management from Harvard University, with many MBA
            classes with Harvard faculty, GPA of 3.95. Suren has done Executive
            Programs in Technology Management and People Management from
            Massachusetts Institute of Technology. <br />
            Suren worked at a few different Cable, Interconnect, and Polymer
            companies. At executive level, he held VP/SVP level positions at 3
            different companies. He was VP of Engineering, VP of Sales and
            Engineering at two companies. He became SVP of Technical Services at
            the most recent work along with being a Board Member. He is an
            extremely hands-on person with superior Technical, Business, and
            People skills. Initial work and agreement on the projects will be
            done by Suren Shah. He also has associates with following skills
            available on an ‘As Needed’ basis.{" "}
          </p> <br />
          <p className=" leading-10 ">  <b><h4> Associates  </h4>  </b>
          <ol className="space-y-5"><li>Research Chemist with 35 years of Direct Experience in Compound Development. </li>
          <li>Director of Engineering with a detailed knowledge of Customer Specifications and Regulatory Agencies.  40 years of experience. </li>
          <li>Director of Process Engineering with 35 years of experience in Low Voltage Cable Manufacturing. </li>
          <li>Director of Engineering with 30 years of experience in Medium Voltage and Low Voltage Cable Manufacturing. </li>
          <li>Several Project Managers with the Strong Skills in Implementations of Theory of Constraints (originally structured by Dr. Eliyu Goldratt). 

</li></ol>
           </p>
        </div>
      </div>
    </div>
  );
};

export default About;

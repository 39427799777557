import React from "react";
import { FaLinkedinIn, FaFacebookF, FaTwitter } from "react-icons/fa";
import logo2 from "../assets/logo2.png";


const Footer = () => {
  return (
    <div className=" bg-gray-200 flex flex-col ">
      <footer className=" bottom-0 left-0 right-0 w-full relative ">
        {/* h-[500px] px-10 mt-auto text-right relative bottom-0 w-full  */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div className="flex items-center mx-10 pt-7">
            <img src={logo2} alt="" />
          </div>
          <div className="mx-20 my-9 flex flex-col items-end justify-center">
            <ul className="space-y-3">
              <li>774-280-4587</li>
              <li>surenrshah4321@outlook.com</li>
              <li>surenrshah@gmail.com</li>
              <li>1333 College Parkway</li>
              <li>No. 1041 Gulf Breeze, FL 32563</li>
              <li className="flex gap-3 ">
                <a href="https://www.linkedin.com/in/suren-shah-2b092482/">
                  <FaLinkedinIn />
                </a>
                <FaFacebookF /> <FaTwitter />
              </li>
            </ul>
          </div>
        </div>

        <span className="flex justify-center bottom-0">
          &copy; 2023- Suren R Shah and Associates
        </span>
      </footer>
    </div>
  );
};

export default Footer;

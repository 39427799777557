import React from "react";
import P_1 from "../assets/P-3_page-0001.jpg";
import P_2 from "../assets/P-3_page-0002.jpg";
import P_3 from "../assets/P-3_page-0003.jpg";
import P_4 from "../assets/P-3_page-0004.jpg";



const Post3 = () => {
  return (
    <div>
      <div class="container w-full md:max-w-3xl mx-auto pt-20">
        <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
          {/* {/_ <!--Title--> _/} */}
          <div class="font-sans">
            <p class="text-base md:text-sm text-black font-bold">
              &lt;{" "}
              <a
                href="/blog"
                class="text-base md:text-sm text-black font-bold no-underline hover:underline"
              >
                BACK TO BLOG
              </a>
            </p>
            <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
            Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱?
            If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗽𝗮𝗿𝘁 𝟯 𝗼𝗳 𝟯.
            </h1>
            <p class="text-sm md:text-base font-normal text-gray-600">
              Published February 2024
            </p>
          </div>

          {/* <!--Post Content--> */}

          {/* <!--Lead Para--> */}
          <p class="py-6">
          In part 2, we talked about the Strategy formulation to move from Red to Less Red to Less Blue to Blue parts of the competitive ocean. In this part-3 we will talk about 𝗜𝗺𝗽𝗹𝗲𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻. 
          <img src={P_1} alt="" />
          <br/>
𝗘𝗳𝗳𝗲𝗰𝘁𝗶𝘃𝗲𝗻𝗲𝘀𝘀 𝗼𝗳 𝘁𝗵𝗲 𝗜𝗺𝗽𝗹𝗲𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻 𝗰𝗮𝗻 𝗺𝗮𝗸𝗲/𝗯𝗿𝗲𝗮𝗸 𝗮 𝗽𝗿𝗼𝗷𝗲𝗰𝘁 𝗼𝗿 𝗮 𝗰𝗼𝗺𝗽𝗮𝗻𝘆.
<br/><br/>
Any well executed strategy requires extraordinary attention to the details from the very beginning. See slide 2.
<br/>
Only a few companies are founded with an eye to blue ocean space from the start. Most companies are in the red ocean and trying to move towards the blue side of the ocean.
<br/><br/><img src={P_2} alt="" />
We must make a distinction between present products/businesses (called Settlers) and future products/businesses (called Pioneers). The implementation requires a different approach for each type.
<br/><br/>
-       Settlers must be run with utmost effectiveness, as Performance Engines. They spin off the cash and resources needed for the Pioneers.
<br/><br/>
-       Pioneers must be launched with careful monitoring as controlled experimentation. Learn quickly from the early experiments.
<br/><br/>
Key Organizational hurdles for the implementation are,<br/>
-       Cognitive. All employees must be on board with this need to move towards the blue.<br/>
-       Limited resources. Pioneers need more resources.<br/>
-       Motivation of all employees.<br/>
-       Politics in making the move to more pioneers. Some people may see that as a threat.<br/>
<br/><br/>
The Execution should be built into the Strategy from the start. A culture of trust and commitment must be established. Practice three ‘Es of Engagement, Explanation, and clear Expectations.
<br/><br/>
Slide 3 shows how TOC and TQM can be valuable tools in the above implementation efforts. The mindset of TOC and TQM matches the needs of blue ocean strategy.
<br/><br/><img src={P_3} alt="" />
They can be perfect to run the Settler products/businesses. They can also offer some unique competitive advantages that provide the basis for launching Pioneer products/businesses.
<br/><br/><img src={P_4} alt="" />
What do you think?
          </p>
          
            <div className=" text-blue-600">
           
           <a className="text-blue" href="https://www.linkedin.com/posts/suren-shah-2b092482_implementation-red-ocean-to-blue-ocean-activity-7162499900931317760-6A66?utm_source=share&utm_medium=member_desktop"> This article was originally posted on my LinkedIn </a>
           </div>
          
        </div>
        {/* <!--Tags --> */}
        <div class="text-base md:text-sm text-gray-500 px-4 py-6">Tags:</div>

        {/* <!--Divider--> */}
        <hr class="border-b-2 border-gray-400 mb-8 mx-4"></hr>
      </div>
    </div>
  );
};

export default Post3;

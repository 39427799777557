import React from "react";
import TOCTQM from "../assets/TOCTQM.jpg";
import Total from "../assets/Total-Q-M.png";
import Plan from "../assets/PlanCycle.png";

const Post1 = ({ title, content }) => {
  return (
    <div className="bg-white shadow-md rounded p-4 mb-4">
      {/* <!--Container--> */}
      <div class="container w-full md:max-w-3xl mx-auto pt-20">
        <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
          {/* <!--Title--> */}
          <div class="font-sans">
            <p class="text-base md:text-sm text-black font-bold">
              &lt;{" "}
              <a
                href="/blog"
                class="text-base md:text-sm text-black font-bold no-underline hover:underline"
              >
                BACK TO BLOG
              </a>
            </p>
            <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
              𝗧𝗼𝘁𝗮𝗹 𝗤𝘂𝗮𝗹𝗶𝘁𝘆 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁 (#𝗧𝗤𝗠). 𝗛𝗼𝘄 𝗱𝗼𝗲𝘀 𝗶𝘁 𝗳𝗶𝘁 𝘄𝗶𝘁𝗵 𝘁𝗵𝗲 𝗧𝗵𝗲𝗼𝗿𝘆
              𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗮𝗶𝗻𝘁𝘀 (#𝗧𝗢𝗖)?
            </h1>
            <p class="text-sm md:text-base font-normal text-gray-600">
              Published June 2023
            </p>
          </div>
          {/* <!--Post Content--> */}
          {/* <!--Lead Para--> */}
          <p class="py-6">
            TOC and TQM together can achieve truly remarkable Bottom-line
            Results.
            <br /> TQM first. Over past 30 plus years, various initiatives have
            contributed to improve our understanding of Total Quality
            Management.
            <br />{" "}
            <ol>
              <li>
                &#8226; Original ISO 9001 provided much needed common language
                around Non-conformances, Corrective Actions, Inspections-
                incoming, in-process, and final.
              </li>
              <li>
                &#8226; Six sigma provided a drive to reduce the variation
                continuously.
              </li>
              <li>
                &#8226; Lean Manufacturing provided framework to organize the
                production to reduce the batch size, improve the flow, and
                enhance Quality Levels.
              </li>
              <li>
                &#8226; Recent version of ISO 9001-2015 added requirements for
                Context of Business, Key Performance Indicators, and Plan for
                Improvements.
              </li>
            </ol>
          </p>
          <img  className="mx-auto" src={Total} alt="" />
          <p className="py-6">
            Different banners aside, what a professor at Harvard said many years
            ago still holds true. TQM is complete when you include the
            following:{" "}
            <ol>
              <li> Continuous Improvement</li>
              <li> Employee participation and</li>
              <li>Customer focus</li>
            </ol>
          </p>
          <img className="mx-auto" src={TOCTQM} alt="" />
          <br />
          <p class="py-6">
            𝗛𝗼𝘄 𝗱𝗼𝗲𝘀 𝗧𝗤𝗠 𝗳𝗶𝘁 𝘄𝗶𝘁𝗵 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 (𝗧𝗢𝗖)? <br />
            <br /> Original book, ‘Goal' by Dr. Eli Goldratt had ‘A Process of
            Ongoing Improvement’ as the subtitle. TOC has been very effective in
            providing the framework for focusing on a true constraint to get the
            maximum performance. It is applied in Manufacturing, Projects,
            Sales/Marketing, Supply Chain/Distribution, Customer Service, and
            Retail.
          </p>
          <img className="mx-auto" src={Plan} alt="" />
          <p className="py-6">
            TOC practitioners commonly use the following parameters to guide
            them: <br />{" "}
            <ol>
              <li>𝟏. 𝐓𝐡𝐫𝐨𝐮𝐠𝐡𝐩𝐮𝐭, 𝐰𝐡𝐢𝐜𝐡 𝐢𝐬 (𝐒𝐚𝐥𝐞𝐬- 𝐓𝐫𝐮𝐞 𝐕𝐚𝐫𝐢𝐚𝐛𝐥𝐞 𝐂𝐨𝐬𝐭𝐬)</li>
              <li>𝟐. 𝐈𝐧𝐯𝐞𝐧𝐭𝐨𝐫𝐲 𝐨𝐫 𝐈𝐧𝐯𝐞𝐬𝐭𝐦𝐞𝐧𝐭𝐬</li>
              <li> 𝟑. 𝐎𝐩𝐞𝐫𝐚𝐭𝐢𝐧𝐠 𝐄𝐱𝐩𝐞𝐧𝐬𝐞𝐬</li>
              <li>
                {" "}
                𝟒. 𝐂𝐚𝐬𝐡 𝐟𝐥𝐨𝐰 𝐈𝐧 𝐌𝐚𝐧𝐮𝐟𝐚𝐜𝐭𝐮𝐫𝐢𝐧𝐠 𝐚𝐧𝐝 𝐏𝐫𝐨𝐣𝐞𝐜𝐭𝐬 𝐞𝐧𝐯𝐢𝐫𝐨𝐧𝐦𝐞𝐧𝐭𝐬, we find
                that many-a-times, the Process/Quality problems are the biggest
                disruptors of flow and create the holes in TOC recommended
                buffers. We recommend that for Manufacturing and Projects
                environment, a 5th parameter be added:
              </li>
              <li>
                5. Scrap 𝗼𝗿 𝗪𝗮𝘀𝘁𝗲 𝗱𝗼𝗹𝗹𝗮𝗿𝘀 (𝗮𝘀 𝗮 𝗺𝗲𝗮𝘀𝘂𝗿𝗲 𝗼𝗳 𝗹𝗮𝗰𝗸 𝗼𝗳 𝗲𝗳𝗳𝗲𝗰𝘁𝗶𝘃𝗲
                𝗧𝗤𝗠) TOC and TQM put together will achieve truly remarkable
                results.
              </li>
            </ol>
            <br />
            <div className=" text-blue-600">
           
            <a className="text-blue" href="https://www.linkedin.com/posts/suren-shah-2b092482_how-tqm-fits-with-toc-activity-7071504245576916992-Sw_Y?utm_source=share&utm_medium=member_desktop"> This article was originally posted on my LinkedIn </a>
            </div>
          </p>
          {/* <!--/ Post Content--> */}
        </div>

        {/* <!--Tags --> */}
        <div class="text-base md:text-sm text-gray-500 px-4 py-6">
          Tags: COO . Cables . Interconnects . Polymers . Cashflow . Consulting
        </div>

        {/* <!--Divider--> */}
        <hr class="border-b-2 border-gray-400 mb-8 mx-4"></hr>
      </div>
    </div>
  );
};

export default Post1;

import React from "react";
import P_1 from "../assets/post4_page-0001.jpg";
import P_2 from "../assets/post4_page-0002.jpg";
import P_3 from "../assets/post4_page-0003.jpg";
import P_4 from "../assets/post4_page-0004.jpg";
import P_5 from "../assets/post4_page-0005.jpg"; 


const Post4 = () => {
  return (
    <div>
      <div class="container w-full md:max-w-3xl mx-auto pt-20">
        <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
          {/* {/_ <!--Title--> _/} */}
          <div class="font-sans">
            <p class="text-base md:text-sm text-black font-bold">
              &lt;{" "}
              <a
                href="/blog"
                class="text-base md:text-sm text-black font-bold no-underline hover:underline"
              >
                BACK TO BLOG
              </a>
            </p>
            <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
            Are you making 𝗺𝗼𝗿𝗲 𝗮𝗻𝗱 𝗺𝗼𝗿𝗲 𝗺𝗼𝗻𝗲𝘆, 𝗽𝗲𝗿𝗶𝗼𝗱 𝗼𝘃𝗲𝗿 𝗽𝗲𝗿𝗶𝗼𝗱? 
            If not, you may continue reading. 𝗥𝗲𝗱 𝗢𝗰𝗲𝗮𝗻 - 𝗕𝗹𝘂𝗲 𝗢𝗰𝗲𝗮𝗻, 𝗽𝗮𝗿𝘁 𝟮 𝗼𝗳 𝟯
            </h1>
            <p class="text-sm md:text-base font-normal text-gray-600">
              Published January 2024
            </p>
          </div>

          {/* <!--Post Content--> */}

          {/* <!--Lead Para--> */}
          <p class="py-6">
          In this part 2, we will talk about the 𝗣𝗿𝗶𝗻𝗰𝗶𝗽𝗹𝗲𝘀 𝗼𝗳 𝗦𝘁𝗿𝗮𝘁𝗲𝗴𝘆 𝗙𝗼𝗿𝗺𝘂𝗹𝗮𝘁𝗶𝗼𝗻. How to move your company from Red Ocean to Light Red to Light Blue to Blue parts of the Ocean?
          <br/>
          <br/>

When you are competing in the Red zone, it feels that for every customer order, you are playing the game of chess with the competition. Slide-1. Typically, you try to distinguish by offering either some differentiation or low cost.
<br/>
<br/>
<img src={P_1} alt="" />
<br/>
<br/>

The strategy for moving in the direction of Blue zone is to do 𝗩𝗮𝗹𝘂𝗲 𝗜𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻 where you offer both: much better value, while providing lower cost. You make the competition irrelevant where the customers are attracted to you, and you rapidly grow the Sales. Slide-2.
<br/>
<br/>
<img src={P_2} alt="" />
<br/>
<br/>

How is that possible? In your product and service offerings,<br/>

·      You 𝗳𝗼𝗰𝘂𝘀 𝗮𝗻𝗱 𝗰𝗿𝗲𝗮𝘁𝗲 𝗱𝗶𝘃𝗲𝗿𝗴𝗲𝗻𝗰𝗲 from the traditional competition.
<br/>

·      You look across traditional market boundaries and create new ones. Look across alternate industries, strategic groups, chain of buyers, complementary product & service offerings, functional versus emotional aspects for the buyers, and even across the time scale.
<br/>
<br/>

·      Slide-3. You reduce some aspects of your offerings to below the industry norm. At same time, you raise some aspects to well above industry norms. You eliminate some factors that are standard. At the same time, you offer some factors that the industry has never offered.
<br/>
<br/>
<img src={P_3} alt="" />
<br/>
<br/>

Slide-4 shows how Southwest Airlines has done the above. Displayed as a Strategic Canvas.
<br/>
<br/>
<img src={P_4} alt="" />
<br/>
<br/>

For companies making products (Chemicals, Polymers, Cables, Mechanical Components) there are many avenues to create divergence in the product and service offerings.
·      Targeted product innovation. 
·      Offer value-add service with the product that is not common.
·      Offer distribution model that is the most efficient for the customer. Think of customers’ distribution as an extension of your own.
·      Aggregate the stocking programs for many customers and manage internally.
<br/>
<br/>
<img src={P_5} alt="" />

The 𝗲𝘅𝗲𝗰𝘂𝘁𝗶𝗼𝗻 makes/breaks these programs. We will talk about that in part 3 of the write-up. Also, how the tools of 𝗧𝗵𝗲𝗼𝗿𝘆 𝗼𝗳 𝗖𝗼𝗻𝘀𝘁𝗿𝗮𝗶𝗻𝘁𝘀 𝗮𝗻𝗱 𝗧𝗼𝘁𝗮𝗹 𝗤𝘂𝗮𝗹𝗶𝘁𝘆 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁 work very well in facilitating the execution.
 

          </p>
          
            <div className=" text-blue-600">
           
           <a className="text-blue" href="https://www.linkedin.com/posts/suren-shah-2b092482_strategy-formulation-red-ocean-to-blue-ocean-activity-7159224292772118529-HZH6?utm_source=share&utm_medium=member_desktop"> This article was originally posted on my LinkedIn </a>
           </div>
          
        </div>
        {/* <!--Tags --> */}
        <div class="text-base md:text-sm text-gray-500 px-4 py-6">Tags:</div>

        {/* <!--Divider--> */}
        <hr class="border-b-2 border-gray-400 mb-8 mx-4"></hr>
      </div>
    </div>
  );
};

export default Post4;

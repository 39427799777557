import React from "react";
import new_6 from "../assets/new-6.jpg";
import new_7 from "../assets/new-7.jpg";

const New_PM = () => {
  return (
    <div>
      {/* content  */}
      <div className=" container w-full md:max-w-3xl mx-auto">
        {/* mx-2 lg:mx-[500px] */}
        <h1 className="p-4 text-2xl text-left">
          Development of New Products, New Materials
        </h1>
        <div className="">
          <img className="mx-auto h-[400px]" src={new_6} alt="" />

          <p className="space-y-8 text-justify leading-9">
            As the Customer expectations change, Industry requirements evolve,
            you need New Products. Most distinctive new products are developed
            with Unique Materials with a combination of Properties and Cost.
            <br /> We can help. We have a highly developed skillset in
            identifying the market needs and designing Products and Polymers to
            fill the need in a cost-effective manner.
            <br /> Of course, you can do the same with your own team members.
            However, you may benefit from having an external set of people
            review the situation. These are the people with deep insights into
            the Market Needs and can match Materials Science with the product
            needs. <br />
            <img className="mx-auto " src={new_7} alt="" />
            The new products can also utilize Unique Designs. And, sometimes
            utilize Unique equipment. <br /> Let us discuss. We can assess the
            Market Needs and inform if a New Product can be developed to allow
            you to leapfrog the competition.
          </p>
        </div>
      </div>
    </div>
  );
};

export default New_PM;

import React from "react";
// import TOCTQM from "../assets/TOCTQM.jpg";
// import Sal_9 from "../assets/sal-9.jpg";


const Services = () => {
  return (
    <div>
      <div className=" bg-white p-10 ">
        <h3 className="font-bold text-3xl grid place-items-center">
          Our Services
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <div className=" bg-gray-300 p-4 h-52">
            {/* service image */}
            <h4>Development of New Products, New Materials</h4>
            <a href="/New_PM">
              <button className="py-2 px-2 rounded border border-black">
                Read More
              </button>
            </a>
          </div>
          <div className=" bg-gray-300 p-4 ">
          
            <div> 
            <h4>Sales Representation on Specialty Products</h4>
            <a href="/Sales">
              <button className="py-2 px-2 rounded border border-black">
                Read More
              </button>
            </a></div>
          </div>
          <div className=" bg-gray-300 p-4 ">
            
            <h4>
              ​Breakthrough Financial Improvements using Theory of Constraints
              and Total Quality Management
            </h4>
            <a href="/TOC_TQM">
              <button className="py-2 px-2 rounded border border-black">
                Read More
              </button>
            </a>
          </div>
          <div className=" bg-gray-300 p-4 h-52">
            {" "}
            {/* service image */}
            <h4>Consulting and Implementation</h4>
            <a href="/Consulting">
              <button className="py-2 px-2 rounded border border-black">
                Read More
              </button>
            </a>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Services;
